import React, { Component, Fragment } from 'react';

import MoneyInput from '~/components/forms/money_input';
import Input from '~/components/forms/input';
import Alert from '~/components/forms/alert';
import Date from '~/components/forms/date';
import Select from '~/components/forms/select';
import Submit from '~/components/forms/submit';
import DeleteIcon from '~/components/forms/delete_icon';

import HttpRequestor from '~/utils/http_requestor';

import moment from 'moment';
import { format, zonedTimeToUtc } from 'date-fns-tz';

export default class TermItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      term: this.props.term,
      isLoading: false,
      error: null,
      success: false,
    };
    this.http = new HttpRequestor();
  }

  onClick = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      error: false,
    });

    setTimeout(() => {
      this.saveTerm();
      this.setState({ isLoading: false });
    }, 1000);
  };

  saveTerm = () => {
    this.http
      .call(this.saveUrl, this.saveMethod, this.state.term)
      .then((response) => {
        if (this.unsaved) {
          this.props.onTermAdd(response);
        }
        this.handleSuccess();
      })
      .catch((e) => {
        this.handleError();
      });
  };

  onDeleteClick = (event) => {
    event.preventDefault();
    const prompt = confirm('Do you want to delete this Term?');

    if (prompt) {
      this.deleteTerm();
    }
  };

  deleteTerm = () => {
    if (this.unsaved) {
      return this.props.removeUnsavedTerm();
    }
    this.http
      .call(this.termUrl, 'DELETE')
      .then((response) => {
        this.props.onTermDeleted(response);
      })
      .catch((e) => {
        this.handleError();
      });
  };

  onChange = (event) => {
    let { name, value } = event.target;
    const penceFields = [
      'price_in_pence',
      'upfront_rent_amount',
      'total_upfront_furniture_in_pence',
      'security_deposit_amount',
      'holding_deposit_amount',
    ];

    if (penceFields.includes(name)) {
      value = (value * 100).toFixed();
    }

    let term = { ...this.state.term, [name]: value };
    this.setState({ term }, () => {
      this.props.onTermUpdated(term);
    });
  };

  handleSuccess = () => {
    if (this.unsaved) {
      return;
    }
    this.setState({ success: true }, () => {
      window.setTimeout(() => {
        this.setState({ success: false });
      }, 4000);
    });
  };

  handleError = () => {
    this.setState({ error: true });
  };

  termPeriodHeading = () => {
    const term = this.state.term;
    return `Term ${this.termDates(term)}`;
  };

  termDates = (term) => {
    return `${this.formatTermHeader(term.start_date)} - ${this.formatTermHeader(term.end_date)}`;
  };

  termStatus = (term) => {
    const { type, text } = this.statusText(term.start_date, term.end_date);
    return <span className={`term__status-label label label-${type} pull-right`}>{text}</span>;
  };

  statusText = (start, end) => {
    if (moment().isBetween(moment(start), moment(end))) {
      return { type: 'success', text: 'Current' };
    }
    if (moment().isBefore(moment(start))) {
      return { type: 'warning', text: 'Upcoming' };
    }
    if (moment().isAfter(moment(end))) {
      return { type: 'danger', text: 'Ended' };
    }
    return { type: 'default', text: '' };
  };

  formatTermHeader = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).format('Do MMMM Y');
  };

  dateFromState = (key) => {
    return this.state.term[key] || '';
  };

  poundsToPence = (penceValue) => {
    return (penceValue / 100).toFixed(2);
  };

  renderTermContracts = () => {
    if (!this.state.term.contracts || this.state.term.contracts.length === 0) {
      return <p>No contracts issued for this Term.</p>;
    }
    return this.state.term.contracts.map((contract) => {
      const issuedAt = contract.issued_at ? zonedTimeToUtc(contract.issued_at, this.props.timezone) : null;

      return (
        <div className="term__contract" key={contract.id}>
          <div className="term__contract-issued">
            <span className="label label-default">{contract.contract_type}</span> Issued{' '}
            <strong>{issuedAt && format(issuedAt, 'E, do MMM yyyy HH:mm')}</strong>
          </div>
          <div className="term__contract-link">
            <a href={contract.document_link}>{contract.document_name}</a>
          </div>
        </div>
      );
    });
  };

  renderDeleteIcon() {
    if (!this.showDeleteIcon) {
      return;
    }

    return (
      <div className="term__delete pull-right">
        <DeleteIcon name={`term_${this.state.id}`} onChange={this.onDeleteClick} />
      </div>
    );
  }

  render() {
    return (
      <div className="panel panel-default term">
        <div className="panel-heading" role="tab">
          <h4 className="panel-title">
            <a className="accordion-toggle" data-toggle="collapse" href={`#${this.state.id}`}>
              {this.termPeriodHeading(this.state.term)}
            </a>
            <div className="term__status">{this.termStatus(this.state.term)}</div>
            {this.renderDeleteIcon()}
          </h4>
        </div>
        <div id={this.state.id} className={`panel-collapse collapse ${this.accordionOpenClass}`}>
          <div className="panel-body">
            <div className="row">
              {this.renderForm()}
              <div className="col-md-4">
                <div className="well term__contracts">
                  <h4 className="term__contracts-title">Contracts</h4>
                  {this.renderTermContracts()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm = () => {
    const {
      original_price_in_pence,
      price_in_pence,
      residents_break_notice_in_months,
      our_break_notice_in_months,
      upfront_rent_months,
      upfront_rent_amount,
      payment_day_of_month,
      total_upfront_furniture_in_pence,
      holding_deposit_amount,
      security_deposit_amount,
    } = { ...this.state.term };

    const leftFields = [
      {
        name: 'original_price_in_pence',
        type: 'money',
        label: 'Original price',
        defaultValue: original_price_in_pence,
        currency: this.props.currency,
        disabled: true,
        helpText: 'Unfurnished price of the property per month, at the time of the term being created',
      },
      {
        name: 'price_in_pence',
        type: 'money',
        label: 'Our Accommodation Price',
        defaultValue: price_in_pence,
        currency: this.props.currency,
        disabled: false,
        helpText:
          'Manually amended unfurnished price of the property per month, excluding the cost of furniture and other services',
      },
      {
        name: 'start_date',
        type: 'date',
        label: 'Start date',
        disabled: this.disableStartDate,
        helpText: 'The start date of this term',
      },
      {
        name: 'end_date',
        type: 'date',
        label: 'End date',
        error: false,
        disabled: false,
        helpText: 'The final date of this term',
      },
      {
        name: 'original_end_date',
        type: 'date',
        label: 'Original end date',
        error: false,
        disabled: !!this.state.id,
        helpText: 'The original end date of this term',
      },
      {
        name: 'total_upfront_furniture_in_pence',
        type: 'money',
        label: 'Total upfront furniture',
        defaultValue: total_upfront_furniture_in_pence,
        currency: this.props.currency,
        helpText:
          'Set when resident accepts upfront payment option, if furniture is added, equal to Our furniture price × Upfront months',
      },
      {
        name: 'upfront_rent_amount',
        type: 'money',
        label: 'Total upfront rent',
        defaultValue: upfront_rent_amount,
        currency: this.props.currency,
        disabled: false,
      },
    ];
    const rightFields = [
      {
        name: 'holding_deposit_amount',
        type: 'money',
        label: 'Holding deposit',
        defaultValue: holding_deposit_amount,
        currency: this.props.currency,
        disabled: false,
      },
      {
        name: 'security_deposit_amount',
        type: 'money',
        label: 'Security deposit',
        defaultValue: security_deposit_amount,
        currency: this.props.currency,
        disabled: false,
      },
      {
        name: 'payment_day_of_month',
        type: 'month',
        label: 'Payment day',
        defaultValue: payment_day_of_month,
        disabled: this.props.useOrganisationPaymentDay,
        includeBlank: true,
        helpText: 'The day of the month on which residents are expected to make their monthly payment(s)',
      },
      {
        name: 'residents_break_date',
        type: 'date',
        label: "Resident's break date",
        error: false,
        disabled: false,
        helpText: 'The earliest date the resident can break the stay',
      },
      {
        label: "Resident's break notice in months",
        type: 'number',
        name: 'residents_break_notice_in_months',
        defaultValue: residents_break_notice_in_months,
        addOnAfter: 'month(s)',
        helpText:
          'The minimum months notice the resident has to provide us to break the stay. If this value is set to 0, the notice period and ability to give notice will not show in the app.',
      },
      {
        name: 'our_break_date',
        type: 'date',
        label: 'Our break date',
        error: false,
        disabled: false,
        helpText: 'The earliest date we can break the stay',
      },
      {
        label: 'Our break notice in months',
        type: 'number',
        name: 'our_break_notice_in_months',
        defaultValue: our_break_notice_in_months,
        addOnAfter: 'month(s)',
        helpText: 'The minimum months notice we have to provide to the resident to break the stay',
      },
      {
        label: 'Upfront rent months',
        type: 'number',
        name: 'upfront_rent_months',
        defaultValue: upfront_rent_months,
        addOnAfter: 'month(s)',
      },
    ];

    return (
      <form>
        <div className="col-md-4">
          {leftFields.map((field, index) => {
            return this.renderField(field, index);
          })}
        </div>
        <div className="col-md-4">
          {rightFields.map((field, index) => {
            return this.renderField(field, index);
          })}
          <Submit
            label={this.buttonLabel}
            onClick={this.onClick}
            disabled={!this.termValid}
            className="term__save btn-block btn-lg"
          />
          {this.renderAlerts()}
        </div>
      </form>
    );
  };

  renderField = (props) => {
    const required = this.state.term.required_fields.includes(props.name);
    const errors = props.disabled ? false : !this.state.term[props.name];
    switch (props.type) {
      case 'number':
        return (
          <Input
            id={`${props.name}_${this.state.term.id}`}
            key={props.name}
            label={props.label}
            type={props.type}
            name={props.name}
            required={required}
            errors={errors}
            defaultValue={props.defaultValue}
            addOnAfter={props.addOnAfter}
            handleChange={this.onChange}
            disabled={props.disabled}
            helpText={props.helpText}
          />
        );
      case 'date':
        return (
          <Date
            id={`${props.name}_${this.state.term.id}`}
            key={props.name}
            label={props.label}
            name={props.name}
            required={required}
            errors={errors}
            value={this.dateFromState(props.name)}
            onChange={this.onChange}
            disabled={props.disabled}
            helpText={props.helpText}
          />
        );

      case 'money':
        return props.name === 'upfront_rent_amount' ? (
          <MoneyInput
            id={`${props.name}_${this.state.term.id}`}
            key={props.name}
            label={props.label}
            name={props.name}
            required={required}
            errors={errors}
            defaultValue={this.poundsToPence(props.defaultValue)}
            currency={props.currency}
            disabled={props.disabled}
            onChange={this.onChange}
            helpText={props.helpText}
            useProRataUpfrontPayment={this.props.useProRataUpfrontPayment}
          />
        ) : (
          <MoneyInput
            id={`${props.name}_${this.state.term.id}`}
            key={props.name}
            label={props.label}
            name={props.name}
            required={required}
            errors={errors}
            defaultValue={this.poundsToPence(props.defaultValue)}
            currency={props.currency}
            disabled={props.disabled}
            onChange={this.onChange}
            helpText={props.helpText}
          />
        );

      case 'month':
        return (
          <Select
            id={`${props.name}_${this.state.term.id}`}
            key={props.name}
            name={props.name}
            required={required}
            errors={errors}
            label={props.label}
            defaultValue={props.defaultValue}
            includeBlank={props.includeBlank}
            helpText={!props.disabled && props.helpText}
            onChange={this.onChange}
            options={this.days}
            disabled={props.disabled}
            disabledText="Payment day set by Organisation"
          />
        );
    }
  };

  renderAlerts = () => {
    return (
      <Fragment>
        <Alert show={this.state.success} type="success" message="Your changes to this Term have been saved :)" />
        <Alert
          show={this.hasError}
          type="danger"
          message="There was an error applying your changes, please try again or contact @engineers"
        />
        <Alert show={!this.termValid} type="danger" message="End date must be after start date" />
      </Fragment>
    );
  };

  get days() {
    let days = [];
    for (let d = 1; d <= 31; d++) {
      days.push([d, d]);
    }
    return days;
  }

  get termValid() {
    return this.startDateValid && this.endDateValid;
  }

  get startDateValid() {
    return this.state.term.start_date;
  }

  get endDateValid() {
    if (!this.state.term.end_date) {
      return true;
    }

    return this.state.term.end_date > this.state.term.start_date;
  }

  get unsaved() {
    return !this.state.id;
  }

  get accordionOpenClass() {
    return this.props.index === 0 ? 'in' : '';
  }

  get hasError() {
    return this.state.error;
  }

  get saveMethod() {
    return this.state.id ? 'PATCH' : 'POST';
  }

  get saveUrl() {
    return this.state.id ? this.termUrl : this.postTermUrl;
  }

  get postTermUrl() {
    return `/stays/${this.props.offer_id}/terms/`;
  }

  get termUrl() {
    return `/stays/${this.props.offer_id}/terms/${this.state.id}`;
  }

  get buttonLabel() {
    if (this.state.isLoading) {
      return 'Saving...';
    }
    return this.unsaved ? 'Add this Term' : 'Save this Term';
  }

  get disableStartDate() {
    return this.props.numberOfTerms > 1 && this.state.term.start_date != null;
  }

  get showDeleteIcon() {
    return this.props.numberOfTerms > 1;
  }
}
