import React from 'react';
import $ from 'jquery';
import 'select2';
import Input from './forms/input';
import Checkbox from './forms/checkbox';
import Delete from './forms/delete';
import InternalOnly from './layouts/internal_only';
import I18n from '../utils/i18n';

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      selectedId: this.props.selectedId || null,
      type: this.props.label.replace(/\s+/g, '_').toLowerCase(),
    };
  }

  componentDidMount() {
    this.initializeSelect2();
  }

  componentDidUpdate() {
    let fields = [
      { element: `${this.props.name}[contact][search]`, field: '' },
      { element: `${this.props.name}[contact][subpremise]`, field: 'SubBuilding' },
      { element: `${this.props.name}[contact][house_name]`, field: 'BuildingName' },
      { element: `${this.props.name}[contact][house_number]`, field: 'BuildingNumber' },
      { element: `${this.props.name}[contact][street_line_1]`, field: 'Street' },
      { element: `${this.props.name}[contact][street_line_2]`, field: 'Line2' },
      { element: `${this.props.name}[contact][town_or_city]`, field: 'City' },
      { element: `${this.props.name}[contact][region]`, field: 'Province' },
      { element: `${this.props.name}[contact][postcode]`, field: 'PostalCode' },
    ];

    let options = { key: 'UD59-AF48-PY47-KZ71' };

    try {
      let control = new pca.Address(fields, options);
      control.load();
    } catch (err) {}
  }

  initializeSelect2() {
    $(this.actionSelect)
      .select2({
        data: this._selectActions(),
        templateResult: this._template,
        templateSelection: this._template,
        escapeMarkup: function (m) {
          return m;
        },
        minimumResultsForSearch: 2,
        width: '100%',
      })
      .on('change', this.itemSelected);
  }

  itemSelected = (e) => {
    this.setState({ selectedId: e.target.value });
  };

  get contact() {
    return this.props.contacts.find((contact) => contact.id == this.state.selectedId);
  }

  getContactAttribute = (contact, attribute) => {
    if (contact) {
      return contact.attributes[attribute];
    }
  };

  _template(data) {
    return data.html;
  }

  _selectActions() {
    var data = this.props.contacts.map((contact) => {
      return {
        id: contact.id,
        value: this._renderContactName(contact),
        text: this._renderContactName(contact),
        html: this._renderOption(contact),
        selected: this.props.selectedId === contact.id,
      };
    });

    return [this._pleaseSelectContactOption(), this._addNewContactOption(), ...data];
  }

  _renderOption(contact) {
    return `
      <div className="">
        &middot; ${this._renderContactName(contact)}
      </div>`;
  }

  _renderContactName(contact) {
    let company = contact.attributes.company == '' ? '' : `, <small>(${contact.attributes.company})</small>`;

    return `${contact.attributes.name}${company}`;
  }

  _pleaseSelectContactOption() {
    return { id: -1, html: `<div>${I18n.t('properties.form.please_select')}</div>` };
  }

  _addNewContactOption() {
    return { id: 0, html: `<div class="add-new-contact">${I18n.t('properties.form.contact.add')}</div>` };
  }

  _renderContactForm() {
    const contact = this.contact;
    return (
      <div className="">
        <div className="form-group string optional address-search">
          <label className="control-label string optional" htmlFor="address-search">
            Address search
          </label>
          <input
            className="form-control string optional"
            placeholder={I18n.t('properties.address_search')}
            name={`${this.props.name}[contact][search]`}
            id="address-search"
            type="text"
          />
        </div>

        <Input
          name={`${this.props.name}[contact][id]`}
          defaultValue={this.state.selectedId > 0 ? this.state.selectedId : null}
          additionalParams={{ style: { display: 'none' } }}
        />

        {this.props.propertyContactId && (
          <input type="hidden" name={`${this.props.name}[id]`} defaultValue={this.props.propertyContactId} />
        )}
        {contact && <input type="hidden" name={`${this.props.name}[contact_id]`} defaultValue={contact.id} />}
        <input type="hidden" name={`${this.props.name}[contact_type]`} defaultValue={this.props.contactType} />

        {this._renderContactFormFields(contact)}
        <Checkbox
          name={`${this.props.name}[contact][notify_viewings]`}
          label={I18n.t('properties.form.contact.notify_viewings.label')}
          defaultChecked={this.notifyViewings}
        />
      </div>
    );
  }

  _renderContactFormFields = (contact) => {
    const fields = [
      'name',
      'company',
      'email',
      'mobile',
      'subpremise',
      'house_name',
      'house_number',
      'street_line_1',
      'street_line_2',
      'town_or_city',
      'region',
      'postcode',
    ];

    return fields.map((field, index) => {
      return (
        <Input
          key={index}
          label={I18n.t(`properties.form.contact.${field}`)}
          name={`${this.props.name}[contact][${field}]`}
          defaultValue={this.getContactAttribute(contact, field)}
        />
      );
    });
  };

  _renderContact() {
    if (this.state.selectedId === null || this.state.selectedId < 0) {
      return;
    }

    if (!this.state.editing && this.state.selectedId > 0) {
      const contact = this.contact;
      return (
        <div>
          <input type="hidden" name={`${this.props.name}[id]`} defaultValue={this.props.propertyContactId} />
          <input type="hidden" name={`${this.props.name}[contact_id]`} defaultValue={contact.id} />
          <input type="hidden" name={`${this.props.name}[contact_type]`} defaultValue={this.props.contactType} />

          <address>
            {contact.attributes.name != null && (
              <div className="contact-content__row">
                <i className="contact-content__info-icon fa fa-address-card-o"></i>
                <strong>{contact.attributes.name}</strong>
              </div>
            )}

            {contact.attributes.email != null && (
              <div className="contact-content__row">
                <i className="contact-content__info-icon fa fa-at"></i>
                <a className="contact-content__link contact-content__email" href={`mailto:${contact.attributes.email}`}>
                  {contact.attributes.email}
                </a>
              </div>
            )}
            {contact.attributes.mobile != null && (
              <div className="contact-content__row">
                <i className="contact-content__info-icon fa fa-phone"></i>
                <a className="contact-content__link contact-content__mobile" href={`tel:${contact.attributes.mobile}`}>
                  {contact.attributes.mobile}
                </a>
              </div>
            )}
          </address>

          <address>
            <strong>{contact.attributes.company}</strong>
            <br />
            {contact.attributes.subpremise != null && <div>{contact.attributes.subpremise}</div>}
            {contact.attributes.house_name != null && <div>{contact.attributes.house_name}</div>}
            {contact.attributes.house_number != null && <div>{contact.attributes.house_number}</div>}
            {contact.attributes.street_line_1 != null && <div>{contact.attributes.street_line_1}</div>}
            {contact.attributes.street_line_2 != null && <div>{contact.attributes.street_line_2}</div>}
            {contact.attributes.town_or_city != null && <div>{contact.attributes.town_or_city}</div>}
            {contact.attributes.region != null && <div>{contact.attributes.region}</div>}
            {contact.attributes.postcode != null && <div>{contact.attributes.postcode}</div>}
          </address>

          <address>{this._renderViewingsCopy}</address>

          <input
            type="button"
            className="btn btn-primary btn-sm"
            value="Edit Contact"
            onClick={() => this.setState({ editing: !this.state.editing })}
          />
        </div>
      );
    }

    return this._renderContactForm();
  }

  get _renderViewingsCopy() {
    const icon = this.notifyViewings ? 'fa-check' : 'fa-times';
    const copy = this.notifyViewings ? 'will' : 'wont';
    return (
      <div>
        <i className={`contact-content__info-icon fa ${icon}`}></i>
        {I18n.t(`properties.form.contact.notify_viewings.${copy}`)}
      </div>
    );
  }

  render() {
    return (
      <div className="contact-content">
        <InternalOnly>
          <div className="contact-content__dropdown">
            <select
              ref={(ref) => (this.actionSelect = ref)}
              id={this.props.name}
              name="status"
              className="contact-dropdown-select"
            ></select>
            <Delete name={`${this.props.name}[delete]`} onChange={this.setDeleteReady} />
          </div>

          <div className={`contact-content__info ${this.deleteItemClass}`}>{this._renderContact()}</div>
        </InternalOnly>
      </div>
    );
  }

  setDeleteReady = () => {
    this.setState({ deleteReady: !this.state.deleteReady });
  };
  get notifyViewings() {
    const contact = this.contact;
    if (contact && contact['attributes']['notify_viewings'] != undefined) {
      return contact['attributes']['notify_viewings'];
    }
  }
  get deleteItemClass() {
    return this.state.deleteReady ? 'for-delete' : '';
  }
}
