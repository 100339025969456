import React, { Component, Fragment } from 'react';
import Date from '../forms/date';
import Select from '../forms/select';
import I18n from '../../utils/i18n';

export default class EndOfTenancyCleaningFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.service.start_date,
    };
  }

  render() {
    return (
      <Fragment>
        <Date
          label={I18n.t('offer_services.form.start_date')}
          name="offer_service[start_date]"
          defaultValue={this.state.date}
          onChange={this.onDateChange}
        />
        <input type="hidden" name="offer_service[effective_cancellation_date]" value={this.state.date} />
        <Select
          label={I18n.t('offer_services.form.end_of_tenancy_cleaning_package_id')}
          name="offer_service[package_id]"
          options={[
            ['Onboarding', 'onboarding'],
            ['Offboarding', 'offboarding'],
          ]}
        />
      </Fragment>
    );
  }

  onDateChange = (e) => {
    this.setState({ date: e.target.value });
  };
}
