import React from 'react';
import Utility from './utility';
import InputSection from './layouts/input_section_header';
import InternalOnly from './layouts/internal_only';
import I18n from '../utils/i18n';

export default class UtilitiesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      utilities: props.utilities === null ? [] : props.utilities,
    };
  }

  render() {
    const children = this.state.utilities.map((utility, i) => {
      return (
        <div className="react-list__item" key={i}>
          <InternalOnly>
            <Utility utilityIndex={i} details={utility} />
          </InternalOnly>
        </div>
      );
    });

    return (
      <InputSection title={I18n.t('properties.form.utilities.pricing_title')}>
        <div className="utilities react-list">{children}</div>
        <small className="pull-left">{this._renderAddButton()}</small>
      </InputSection>
    );
  }

  _renderAddButton() {
    return (
      <button onClick={this.addUtility} className="btn btn-primary">
        {I18n.t('properties.form.utilities.add')}
      </button>
    );
  }

  addUtility = (event) => {
    event.preventDefault();
    let newUtilities = this.state.utilities.slice();
    newUtilities.push({});
    this.setState({ utilities: newUtilities });
  };
}
