import React, { Component, Fragment } from 'react';
import HelloSign from 'hellosign-embedded';
import I18n from '../utils/i18n';

export default class EmbeddedSigningButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      client: undefined,
    };
  }

  componentDidMount() {
    this.onHelloSignLoaded();
  }

  render() {
    const { loaded, error } = this.state;

    if (!loaded) {
      return <span className="label label-info">Loading...</span>;
    }

    return (
      <Fragment>
        {this.renderSigningButton()}
        {error && (
          <span className="hellosign-embedded-signing__error-message label label-sm label-danger">
            An error occured 😿
          </span>
        )}
      </Fragment>
    );
  }

  renderSigningButton = () => {
    if (this.props.signatureUrl == 'signed') {
      return null;
    }

    return (
      <button className="btn btn-default btn-sm btn-primary" onClick={this.onReadyToSignClicked}>
        {I18n.t('contracts.status.ready_to_sign')}
      </button>
    );
  };

  onHelloSignLoaded = () => {
    const client = new HelloSign({ clientId: this.props.helloSignClientId });

    this.setState({ loaded: true, client: client });
  };

  onReadyToSignClicked = () => {
    this.setState({ error: false });
    const client = this.state.client;

    client.open(this.props.signatureUrl, {
      allowCancel: true,
      skipDomainVerification: this.props.helloSignSkipDomainVerification,
      container: document.getElementById(`contract-container-${this.props.index}`),
    });

    client.on(HelloSign.events.SIGN, () => {
      location.reload();
    });

    client.on('error', (data) => {
      client.close();
      this.setState({
        error: true,
        data: data,
      });
    });
  };
}
