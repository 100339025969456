import React, { Component } from 'react';
import ImageUpload from './forms/image_upload';
import Input from './forms/input';
import Delete from './forms/delete';
import 'whatwg-fetch';
import I18n from '../utils/i18n';

export default class PropertyImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteReady: false,
    };
  }

  render() {
    return (
      <div className={this.classes}>
        <div className="property-image__inner">
          <input
            type="hidden"
            name={`property[property_images][${this.props.itemIndex}][id]`}
            defaultValue={this.props.image.id}
          />
          <div className="property-image__header">
            <Delete
              name={`property[property_images][${this.props.itemIndex}][delete]`}
              onChange={this.setDeleteReady}
            />
          </div>
          {this.props.displayCaption && (
            <Input
              className="property-image__caption"
              type="text"
              id={`property-image__caption--${this.props.itemIndex}`}
              label={I18n.t('properties.form.images.caption')}
              name={`property[property_images][${this.props.itemIndex}][caption]`}
              defaultValue={this.props.image.attributes.caption}
            />
          )}
          <ImageUpload
            name={`property[property_images][${this.props.itemIndex}][url]`}
            signedUrlEndpoint={this.fetchSignedUrlEndpoint}
            initialUrl={this.url}
            file={this.props.image.attributes.file}
            initialProcessedUrl={this.processedUrl}
            afterUpload={this.createPropertyImage}
            image={this.props.image}
            uploading={this.props.uploading}
          />
        </div>
      </div>
    );
  }

  createPropertyImage = (data) => {
    const url = data.url;
    return fetch(this.props.newPropertyImageUrl, {
      method: 'POST',
      headers: this.headers,
      credentials: 'same-origin',
      body: JSON.stringify({
        url,
        uploadable_id: this.props.uploadable_id,
        uploadable_type: this.props.uploadable_type,
        order: this.props.itemIndex + 1,
      }),
    })
      .then((response) => response.json())
      .then(
        (response) =>
          new Promise((resolve, _reject) => {
            this.props.onImageUpdate(
              this.props.itemIndex,
              { id: response.propertyImageId, attributes: { url } },
              resolve
            );
          })
      );
  };

  setDeleteReady = () => {
    this.setState({ deleteReady: !this.state.deleteReady });
  };

  onPrimaryChange = (_e) => {
    this.props.onPrimaryChange(this.props.itemIndex);
  };

  get classes() {
    return ['property-image', 'well', 'react-list__item', this.state.deleteReady ? 'for-delete' : ''].join(' ');
  }

  get url() {
    return this.props.image.attributes.url;
  }

  get processedUrl() {
    return this.props.image.attributes.processed_url;
  }

  get fetchSignedUrlEndpoint() {
    return `/property_images/${this.props.property_id}/signed_url`;
  }

  get patchPropertyImageUrl() {
    return `/property_images/${this.props.image.id}`;
  }

  get headers() {
    return {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': this.props.authenticityToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }
}
